.survey-form-wrapper {
    .MuiBox-root {
        width: 100%;
        max-width: 100% !important;
    }

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: #73C5DC;
    }

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
        color: #73C5DC;
    }

    .MuiStepLabel-label {
        color: #0B2E64 !important;
        font-size: 10px;
        font-weight: 400 !important;

        .MuiTypography-body1 {
            font-family: "Times New Roman";
            font-size: 8px;
            font-weight: 600;
            line-height: 9px;
            letter-spacing: 0em;
            text-align: left;
            color: #73C5DC;
        }
    }

    .ques-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .ques-num {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: #73C5DC;
        }

        .ques-name {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: #0B2E64;
        }
    }

    .option-radio {
        .MuiButtonBase-root {
            color: #C39D00;
        }

        .MuiTypography-body1 {
            font-family: "Times New Roman";
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #6B7280;
        }
    }

    .input-box{
        display: flex;
        margin-bottom: 8px;
    }

    .css-1qm1lh {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;

        button {
            text-transform: capitalize;
            background: #C3981A;
            height: 46px;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: white;
        }

        .back-btn-disbaled {
            opacity: 0.5 !important;
            color: white;
        }

    }

    .css-1pe7n21-MuiStepConnector-root {
        display: none !important;
    }
    .feedback-txt{
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #0B2E64;
    }
}