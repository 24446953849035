.contact-us-wrapper {
    width: 35%;
    margin: auto;

    .send-feed-head {
        font-family: "Times New Roman";
        font-size: 30px;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: -1px;
        text-align: center;
        color: #C39D00;
    }

    /* Hide the file input */
.file-input-rec {
    display: none;
  }
  
  /* Apply styling to the parent element (Form.Group) */
  .file-input-rec + label {
    pointer-events: none;
    background: white;
    height: 60px;
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 0.75rem 0rem 0.75rem 0.75rem;
  }
  
  /* Style the SVG */
  .file-input-rec + label .file-img {
    float: right;
    position: relative;
    width: unset;
    right: 10px;
    height: 100%;
    cursor: pointer;
    pointer-events: auto
  }
  
  .up-doc-txt{
    float: left;
    font-family: "Times New Roman";
    font-size: 16px;
    font-weight: 400;
    color: #111827;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 5px;
  }
  /* Optionally style the label when a file is selected */
  .file-input-rec:checked + label {
    border: 1px solid #007bff; /* Change the border color */
    background-color: #e0e0e0; /* Change the background color */
  }
  
    .sub-head {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: "Times New Roman";
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: center;
        color: #111827;
    }

    .reset-btn {
        width: 100%;
        border-radius: 4px;
        background: #C39D00;
        border-color: #C39D00;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
    }

    /* Style the placeholder text for all input fields */
    ::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }

    /* Style the placeholder text for a specific input field by targeting its ID */
    #feedbackTitle::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }
    #message::placeholder {
      font-family: "Times New Roman";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #111827;
  }

    /* Style the placeholder text for a specific input field by targeting its class */
    .feedback-input::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }

}