.delete-acc-wrapper {
    .modal-dialog {
        .modal-content {
            width: 385px !important;
            margin: auto !important;
            min-height: 280px !important;
            border-radius: 20px !important;
            background-color: #F1F2F2;
        }

        .delete-body-wrapper {
            .del-label {
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #565957;
            }

            .del-txt-box {
                height: 44px;
                width: 100%;
                border: 0.5px solid #A1A1A1;
                border-radius: 8px;
                margin-top: 5px;
            }
        }

        .modal-header {
            border: none;
            padding-bottom: 0px;

            .logout-title {
                display: block;
                font-family: "Times New Roman";
                font-size: 24px !important;
                font-weight: 300 !important;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding-top: 0px;
        }

        .del-sub {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #6B7280;
        }

        .del-buttons-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .delBtn {
                height: 40px;
                border-radius: 4px;
                color: white;
                background: #C39D00;
                border-color: #C39D00;
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
            }

            .cancelBtn {
                height: 40px;
                border-radius: 4px;
                background: #FEF2F2;
                border-color: #FEF2F2;
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
                color: #991B1B;
            }
        }
    }
}