@import '../../../assets/styles/sass/Common/mixin';
.signup-wrapper {
    display: flex;
    align-items: center;

    .org-type-dropdown {
        position: relative;
        z-index: 999;
    }
    .phone-input {
        input {
            height: 58px;
            width: 100%;
            border-radius: 8px;
            border: var(--bs-border-width) solid var(--bs-border-color);
        }
    }

    .SignupConfirmation-Wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .acc-creation-txt {
            font-family: "Times New Roman";
            font-size: 24px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #111827;
        }

        .info-img {
            display: flex;
            align-items: flex-start;
        }

        .acc-creation-sub-txt {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
            color: #6B7280;
        }
    }

    .css-1nmdiq5-menu {
        display: block;
        z-index: 2;
    }

    .css-13cymwt-control {
        height: 50px;
        border-radius: 8px;
        border: 1px solid #D1D5DB;

        .css-1jqq78o-placeholder {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #9CA3AF;
        }

        .css-1dimb5e-singleValue {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }

        .css-qbdosj-Input {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }
    }

    .css-t3ipsp-control {
        height: 50px;
        border-radius: 8px;
        border: 1px solid #D1D5DB;

        .css-1jqq78o-placeholder {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #9CA3AF;
        }

        .css-1dimb5e-singleValue {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }
    }

    .org-type-item {
        // border-bottom: 1px solid #4B5563;
        padding: 10px;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #111827;
        cursor: pointer;
        height: 30px;

    }

    .org-type-item:hover {
        background-color: #9CA3AF;
    }

    .signup-img-wrapper {
        img {
            width: 50vw;
            height: 100vh;
            object-fit: cover;
        }

        .landing-page-txt {
            cursor: pointer;
            padding: 10px;
            border-radius: 16px;
            position: absolute;
            left: 15px;
            top: 15px;
            background: #FFFFFFA3;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;

        }
    }

    .signup-form {
        width: 50vw;
        background: #E9ECFF;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .signup-form-body {
            background-color: white;
            padding: 20px 50px;
            margin: 0px 40px;
            border-radius: 16px;

            .signin_heading {
                font-family: "Times New Roman";
                font-size: 24px;
                font-weight: 300;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
                margin-top: 15px;
            }

            .signin_subheading {
                font-family: "Times New Roman";
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #9CA3AF;
            }

            .form-floating {
                input {
                    min-height: 50px;
                    height: 50px;
                    border-radius: 8px;
                    border: 1px solid #D1D5DB;
                    padding: 0.25rem 0.75rem;
                    color: #1F2937;
                    font-family: "Times New Roman";
                }

                label {
                    font-family: "Times New Roman";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #9CA3AF;
                    z-index: 1;
                }
            }
        }

        .remember_me {
            font-family: "Times New Roman";
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #9CA3AF;
        }

        .terms_span {
            cursor: pointer;
            color: #7DC5DD;
        }

        .signin_btn {
            background-color: #C39D00;
            border-color: #C39D00;
            border-radius: 4px;
            width: 100%;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #FFFFFF;
        }
    }

    .no-account-txt {
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #4B5563;
    }

    .login_here {
        text-decoration: underline;
        cursor: pointer;
        color: #C39D00;
        font-weight: 700;
    }
}

@include mq('tablet', max){
    .signup-wrapper{
        .signup-form{
            width: 100vw !important;
        }
        .signup-form-body{
            margin: 0px !important;
            padding: 20px !important;            
            margin-top: 10px !important;
        }
    }
}