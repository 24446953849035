@import '../../../assets/styles/sass/Common/mixin';

.main{
    position: relative;
    margin-top: 90px !important;
    background-color: #E9ECFF;
    // padding-left: 100px !important;
    // padding-right: 100px !important;
}
.submain{
    position: relative;
    margin-bottom: 100px !important;
    background-color: #FFFFFF;
    // padding-left: 100px !important;
    // padding-right: 100px !important;
}
.arrowContainer{
    height: 50px;
    width: 50px;
    border-radius: 16px;
    background-color: #FFFFFF;
}
.surveyResultHeader{
    color: #C39D00;
    font-family: "Times New Roman" !important;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: left;
    text-transform: capitalize;
}
.surveyChartContainer{
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
}
.surveyName{
    font-family: "Times New Roman" !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: -1px !important;
    text-align: left;
    color: #2E363E !important;
}
.surveyPercentage{
    font-family: "Times New Roman" !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    color: #000000 !important;
}
.surveyDate{
    font-family: "Times New Roman"!important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    letter-spacing: 0px !important;
    color: #223061 !important;

}
.surveyRatio{
    font-family: "Times New Roman" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    color: #000000 !important;
}
.surveyFeedback{
    font-family: "Times New Roman" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

}

.outer-bar-container {
    position: relative;
    display: inline-block;
    height: 96px;
    width: 96px;
}

.inner-bar-container {
    height: 67px;
    width: 67px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sectionName{
    
padding: 9px 25px 9px 25px !important;
border-radius: 4px !important;
color:#FFFFFF;
background-color: #0B2E64;

font-family: "Times New Roman" !important;
font-size: 12px !important;
font-weight: 700 !important;
line-height: 18px !important;
letter-spacing: 0em !important;
text-align: center;
margin-bottom: 5px !important;


}
.resultText{
    font-family: "Times New Roman" !important;
    font-size: 24px !important;
    font-weight: 300 !important;
    line-height: 28px !important;
    letter-spacing: -1px;
    text-align: left;

}
.resultSectionName{
    font-family: "Times New Roman" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: center !important;

    color: #7E868E !important;
    padding: 9px 25px 9px 25px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
    border-radius: 4px;
    border: 1px solid #7E868E;
    cursor: pointer;

}
.resultSelectedSectionName{
    font-family: "Times New Roman" !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: center !important;

    color: #FFFFFF !important;
    padding: 9px 25px 9px 25px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
    border-radius: 4px;
    border: 1px solid #73C5DC;
    background-color: #73C5DC;

}
.resultAnalytics{
    padding: 20px 20px 20px 20px !important;
    border-radius: 6px !important;
    background-color: #EDF1F7;

    font-family: "Times New Roman" !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: center;
    width: 95%;
   word-wrap:break-word;
    

}
.resultWrongQuestion{
    font-family: "Times New Roman" !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    text-align: left;
    color: #0B2E64;
    margin-top: 5px !important;
    margin-bottom:2px !important;

}
.missing{
    font-family: "Times New Roman" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    letter-spacing: 0px !important;
    text-align: left;
    color:#FB3449 !important;
    margin-bottom:5px 
}
.underline{
    min-height: 5px;
    border-radius: 5px;
    margin-top: 5px !important;
    background-color: #203063;;
}

