@import '../../../assets/styles/sass/Common/mixin';

.settings-wrapper {
    padding-top: 90px;
    padding-bottom: 100px;
    justify-content: space-between;

    .disabledFormFLoating {
        input {
            color: #9CA3AF !important;
        }
    }

    .phone-float {
        input {
            width: 100%;
        }
    }

    .signup-img-wrapper {
        position: relative;

        img {
            width: 50vw;
            height: 80vh;
            object-fit: cover;
        }

        .back-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        .landing-page-txt {
            cursor: pointer;
            padding: 10px;
            border-radius: 16px;
            position: absolute;
            left: 15px;
            top: 15px;
            background: #FFFFFFA3;
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;

        }
    }

    .signup-form {
        height: 80vh;
    }

    .signin_heading {
        font-family: "Times New Roman";
        font-size: 24px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #111827;
    }

    .css-16xfy0z-control {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #9CA3AF;
    }

    .css-olqui2-singleValue {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #9CA3AF;
    }

    .org-type-item {
        // border-bottom: 1px solid #4B5563;
        padding: 10px;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #111827;
        cursor: pointer;
        height: 30px;

    }

    .org-type-item:hover {
        background-color: #9CA3AF;
    }

    .org-type-dropdown {
        position: relative;
        z-index: 999;
    }

    .css-1nmdiq5-menu {
        display: block;
        z-index: 2;
    }

    .css-13cymwt-control {
        height: 50px;
        border-radius: 8px;
        border: 1px solid #D1D5DB;

        .css-1jqq78o-placeholder {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #9CA3AF;
        }

        .css-1dimb5e-singleValue {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }

        .css-qbdosj-Input {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }
    }

    .css-1jqq78o-placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #9CA3AF;
    }

    .css-t3ipsp-control {
        height: 50px;
        border-radius: 8px;
        border: 1px solid #D1D5DB;

        .css-1dimb5e-singleValue {
            font-family: "Times New Roman";
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }
    }
}

@include mq('tablet', max) {
    // .settings-wrapper{
    //     padding-top: 90px !important;
    //     padding-bottom: 125px !important;
    //     justify-content: space-between;
    // }
    
}