@import './assets/styles/sass/Common/mixin';

.App {
  text-align: center;
  // max-height: 100vh;
  // overflow-y: auto;
}

// hide default password eye icon
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

.phone-input {
  input {
    height: 58px;
    width: 100%;
    border-radius: 8px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    padding-left: 46px !important;
  }
}

.react-tel-input .country-list .country {
  text-align: left !important;
}

// hide number arrows
/* Hide the up and down arrows for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

/* Optional: You can add styles to make it visually clear that it's not a normal input */
input[type="number"] {
  padding-right: 20px;
  /* Add some right padding to avoid overlapping with the clear button (X) in some browsers */
}


.invalid-feedback {
  text-align: left;
  display: block !important;
  font-size: 12px;
  margin-top: 0px;
}

.required-icon {
  color: black;
}

@font-face {
  font-family: "Times New Roman";
  // src: url('../src/fonts/Century-Gothic.ttf') format('ttf');
}

.page-data {
  padding-top: 150px;
  padding-bottom: 220px;
  padding-right: 70px;
  padding-left: 70px;
}

@include mq('tablet', max) {
  .page-data {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 130px !important;
    padding-bottom: 120px !important;
  }
}