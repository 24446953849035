@import '../../assets/styles/sass/Common/mixin';

.bottom-navbar {
    position: fixed;
    gap: 10px;
    bottom: 0px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around !important;
    width: 100%;
    background: #203063;
    height: 100px;
    padding: 0px 40px !important;

    .navbar-logo-img {
        cursor: pointer;
    }

    .copyright-txt {
        font-family: "Times New Roman";
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #9CA3AF
    }

    .navbar-logo {
        height: 56px;
        width: 250px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .bottom-nav-links {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 30px;
        width: 50%;
    }

    .nav-items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        border: none !important;

        a {
            font-family: "Times New Roman";
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0em;
            text-align: left;
            text-decoration: none;
            color: #FFFFFF;
        }
    }
}

@include mq('tablet', max) {
    .bottom-navbar {
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: 160px !important;
        padding: 0px 10px !important;

        .navbar-nav {
            justify-content: space-around !important;
        }
    }

    .navbar-logo {
        height: 56px;
        width: 80% !important;
        margin-top: 10px;
    }

    .bottom-nav-links {
        flex-direction: column;
        justify-content: space-around !important;
        width: 100% !important;
        gap: 5px !important;
    }

    .nav-items {
        a {
            font-size: 14px !important;
        }
    }
}