@import '../../../assets/styles/sass/Common/mixin';

.page-content {
    margin-top: 90px;
    margin-bottom: 100px;
    // overflow-y: auto;
    // /* Enable vertical scrolling when content overflows */
    // height: calc(100vh - 120px);
    /* Adjust the height to fit your layout */
    position: relative;

    .nav-page-body {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .home-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .home-text-body {
            text-align: left;
            gap: 10px;
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 70px;

            .home-head {
                font-family: "Times New Roman";
                font-size: 22px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: -1px;
                text-align: left;
                color: #2E363E;
            }

            .home-body {
                font-family: "Times New Roman";
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
            }

            .take-survey-btn {
                background: #C39D00;
                width: 40%;
                border-color: #C39D00;
                border-radius: 4px;
                font-family: "Times New Roman";
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .home-img {
            height: 500px;
            margin-top: 50px;
        }
    }

    .about-us-section {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: #E9ECFF;
        padding: 40px;

        .home-text-body {
            text-align: left;
            gap: 10px;
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 70px;

            .home-head {
                font-family: "Times New Roman";
                font-size: 22px;
                font-weight: 700;
                line-height: 40px;
                letter-spacing: -1px;
                text-align: left;
                color: #2E363E;
            }

            .home-body {
                font-family: "Times New Roman";
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
            }

            .take-survey-btn {
                background: #C39D00;
                // width: 35%;
                border-color: #C39D00;
                border-radius: 4px;
                font-family: "Times New Roman";
                font-size: 18px;
                font-weight: 700;
                line-height: 27px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFF;
            }
        }

        .home-img {
            height: 500px;
            // margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;

            video {
                border-radius: 16px;
                width: 100%;
                // height: 100%;
            }
        }
    }

    .accordion-button {
        background: #E5E7EB;
        font-family: "Times New Roman";
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
        padding: 10px;
    }

    .accordion-button::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        /* Adjust size as needed */
        border-bottom: 5px solid transparent;
        /* Adjust size as needed */
        border-left: 5px solid black;
        /* Change color or replace with an image/icon */
        margin-right: 8px;
        /* Adjust spacing as needed */
        vertical-align: middle;
        transform: rotate(90deg);
        transition: transform 0.2s;
    }

    .faq-accordian-title {
        border-bottom: 1px solid #374151;
        /* Replace 'your-color' with your desired color */
        margin-bottom: 10px;
        /* Adjust the margin as needed */
        padding-bottom: 10px;
    }

    /* Styling for the toggle arrow when the accordion is collapsed (closed) */
    .accordion-button.collapsed::after {
        transform: rotate(-90deg);
        border-left: 5px solid white;
    }

    .faq-section {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto;

        .faq-head {
            font-family: "Times New Roman";
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
            letter-spacing: -1px;
            text-align: center;
            color: #C39D00;
        }

        .faq-sub-title {
            font-family: "Times New Roman";
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0px;
            text-align: center;
            color: #111827;
            padding: 20px 65px 20px 65px;
        }

        .faq-body-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .faq-items-body {
            font-family: "Times New Roman";
            font-size: 14px;
            font-weight: 400;
            line-height: 23px;
            text-align: left;
            color: #6B7280;
            padding: 10px;
            border-top: 1px solid black;
        }

        .accordion-item {
            background: #E5E7EB;
            border-radius: 8px;
        }
    }

    .contact-us-section {
        background: #E9ECFF;
        padding-top: 40px;
        padding-bottom: 40px;

        .contact-us-head {
            font-family: "Times New Roman";
            font-size: 30px;
            font-weight: 300;
            line-height: 40px;
            letter-spacing: -1px;
            text-align: center;
            color: #C39D00;
        }

        .contact-us-sub-title {
            font-family: "Times New Roman";
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0px;
            text-align: center;
            color: #111827;
            padding: 20px 65px 20px 65px;
            width: 50%;
            margin: auto;
        }
    }
}

@include mq('tablet', max) {
    .page-content {
        margin-bottom: 155px !important;
    }

    .nav-page-body {
        gap: 10px !important;

        .home-section {
            flex-direction: column;
            padding: 20px !important;

            .home-text-body {
                width: 100% !important;
                padding: 0px;
            }

            .take-survey-btn {
                width: 100% !important;
            }

            .home-img {
                width: 80%;
                margin-top: 0px !important;
                height: 280px !important;

                img {
                    object-fit: cover;
                }
            }

        }

        .about-us-section {
            flex-direction: column;
            padding: 20px;

            .home-text-body {
                width: 100% !important;
                padding: 0px
            }

            .take-survey-btn {
                width: 100% !important;
            }

            .home-img {
                margin-top: 0px !important;
                height: auto !important;
                width: 80%;
            }
        }

        .faq-section {
            width: 90% !important;

            .faq-sub-title {
                padding: 20px !important;
            }
        }

        .contact-us-section {
            padding-top: 20px !important;
            padding-bottom: 20px !important;

            .contact-us-sub-title {
                width: 90% !important;
                padding: 0px !important;
            }

            .contact-us-wrapper {
                width: 90% !important;
            }
        }
    }
}