.contact-us-wrapper {
    width: 30%;
    margin: auto;

    .reset-btn {
        width: 100%;
        border-radius: 4px;
        background: #C39D00;
        border-color: #C39D00;
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
    }
    /* Style the placeholder text for all input fields */
    ::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }

    /* Style the placeholder text for a specific input field by targeting its ID */
    #feedbackTitle::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }

    /* Style the placeholder text for a specific input field by targeting its class */
    .feedback-input::placeholder {
        font-family: "Times New Roman";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #111827;
    }

}