.terms-conditions-wrapper {
    background: #F2F6FA;
    min-height: 100vh;

    .terms-head {
        font-family: "Times New Roman";
        font-size: 34px;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: -1px;
        text-align: center;
        color: #C39D00;
    }

    .terms-divider {
        border: 4px solid #C39D00;
        width: 65px;
        border-radius: 5px;
        margin: auto;
        margin-top: 10px;
    }

    .terms-body {
        margin-top: 20px;
        font-family: "Times New Roman";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -1px;
        text-align: left;
        color: #6B7280;
        margin-bottom: 4rem;
    }
}