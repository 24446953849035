@import '../../../assets/styles/sass/Common/mixin';

.survey-detail-wrapper {
    .modal-dialog {
        max-width: 50% !important;
        margin: auto !important;

        .modal-body {
            background: #E9ECFF;
            border-radius: 10px;
            max-height: 90vh;
            overflow-y: auto;
        }
    }

    .outer-bar-container {
        position: relative;
        display: inline-block;
        height: 96px;
        width: 96px;
    }

    .inner-bar-container {
        height: 67px;
        width: 67px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .survey-detail-body {
        gap: 20px;
        flex-direction: column;

        .detail-parent {
            border-radius: 16px;
            background-color: #FFFFFF;
            padding: 10px;
            width: 100%;

            .detail-parent-head-wrapper {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .detail-parent-title {
                    // width: 80%;
                    font-family: "Times New Roman";
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: -1px;
                    text-align: left;
                    color: #2E363E;
                }

                .detail-parent-percentage {
                    font-family: "Times New Roman";
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #000000;
                }
            }

            .detail-parent-subhead-wrapper {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                margin-top: 6px;

                .detail-parent-date {
                    font-family: "Times New Roman";
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #223061;
                    // width: 80%;
                }

                .detail-parent-data {
                    font-family: "Times New Roman";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #000000;
                }
            }

            .detail-parent-sub-title {
                font-family: "Times New Roman";
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
                // width: 80%;
            }
        }

        .detail-child {
            border-radius: 16px;
            background-color: #FFFFFF;
            padding: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .detail-child-title {
                font-family: "Times New Roman";
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -1px;
                text-align: left;
                color: #2E363E;
            }

            .detail-child-subtitle {
                font-family: "Times New Roman";
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #111827;
                // margin-top: 6px;
            }

            .detail-child-subtitle {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: calc(1.2em * 3);
                /* Adjust line height and number of lines as needed */
                line-height: 1.2em;
                /* Adjust line height as needed */
            }

            /* Optional: If you want to add an ellipsis (...) */
            .detail-child-subtitle::after {
                content: '...';
                display: inline-block;
                vertical-align: bottom;
                margin-left: 2px;
                /* Adjust as needed for spacing */
            }


            .detail-body {
                gap: 10px;
                justify-content: space-between;
                margin-top: 10px;
                max-width: 100%;
                overflow-x: auto;
            }

            .section-body-wrapper {
                flex-direction: column;
                gap: 20px;
                align-items: center;

                .section-name {
                    width: 96px;
                    max-width: 96px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    max-height: 30px;
                    background: #0B2E64;
                    border-radius: 4px;
                    padding: 8px;
                    font-family: "Times New Roman";
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #FFFFFF;
                }
                .section-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    // left: 25%;
                    height: 50%;
                    position: relative;

                }

                .section-answers {
                    font-family: "Times New Roman";
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #0B2E64;
                }
            }
        }
    }
}

@include mq('tablet', max) {
    .survey-detail-wrapper {
        .modal-dialog {
            padding-top: 130px;
            padding-bottom: 120px;
            max-width: 90% !important;
        }
    }
}